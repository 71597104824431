import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Product from "../components/shop/Product"

export const query = graphql`
  query CatalogQ($slug: String!) {
    sanityCatalog(slug: { current: { eq: $slug } }) {
      id
      slug {
        current
      }
      seo {
        metaTitle
        metaDescription
        metaImage {
          asset {
            url
          }
        }
      }

      products {
        slug {
          current
        }
        title
        price
        sku
        productExternalLink
        priceID
        # link {
        #   label
        #   link
        # }
        texte: _rawTexte(resolveReferences: { maxDepth: 10 })
        infos: _rawProductInfos(resolveReferences: { maxDepth: 10 })
        images: _rawImages(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`

const Shop = ({ data }) => {
  const { slug, seo, products } = data.sanityCatalog
  // console.log(seo)
  return (
    <div className="page">
      {seo && (
        <Seo
          metaTitle={seo.metaTitle}
          metaDescription={seo.metaDescription}
          metaImage={seo.metaImage?.asset.url}
          template={`template-catalog page-${slug.current}`}
          page={true}
        />
      )}
      <section className="products-ui">
        {products.map((item, i) => (
          <Product input={item} i={i} key={i} />
        ))}
      </section>
    </div>
  )
}

export default Shop
