import React, { useEffect, useRef, useState } from "react"
// import PubSub from "pubsub-js";
import styled from "styled-components"

const Container = styled.div`
  position: absolute;
  top: 0;
  /* left: ${props => (props.label === "prev" ? 0 : "unset")}; */
  /* right: ${props => (props.label === "next" ? 0 : "unset")}; */
  &.slick-prev {
    left: 0;
  }
  &.slick-next {
    right: 0;
  }
  width: 20%;
  height: 100%;
  z-index: 49;
  /* opacity: 0; */
  cursor: none;
  mix-blend-mode: difference;
  @media all and (max-width: 1080px) {
    opacity: 0;
  }
  &:hover .label {
    opacity: 1;
  }
  * {
    pointer-events: none;
  }
`
const Label = styled.div`
  opacity: 0;
  position: absolute;
  will-change: transform;
  transform: translate(-100vw, -100vh);
  transform-origin: center center;
  text-align: center;
  font-size: 1em;
  color: white;
`

const SliderCursorPrevNextText = props => {
  // console.log(props);
  const { label, left, className, style, onClick } = props
  const refContainer = useRef()
  const refLabel = useRef()
  const [positions, setPositions] = useState({
    transform: "translate(-100vw, -100vh)",
  })

  useEffect(() => {
    const container = refContainer
    container.current.addEventListener("mouseenter", _onEnter)
    container.current.addEventListener("mouseleave", _onLeave)
    return () => {
      if (container && container.current) {
        container.current.removeEventListener("mouseenter", _onEnter)
        container.current.removeEventListener("mouseleave", _onLeave)
        container.current.removeEventListener("mousemove", _update)
      }
    }
  }, [])

  const _onEnter = e => {
    refContainer.current.addEventListener("mousemove", _update)
  }
  const _onLeave = e => {
    refContainer.current.removeEventListener("mousemove", _update)
  }

  const _update = e => {
    // const { offsetX, offsetY } = e;
    const { width, height } = refLabel.current.getBoundingClientRect()
    const left = e.offsetX - width / 2
    const top = e.offsetY - height / 2
    setPositions({
      transform: `translate(${left}px, ${top}px)`,
    })
  }

  return (
    <Container
      className={`${className} hidden-sm`}
      style={{ ...style }}
      onClick={onClick}
      left={left}
      ref={refContainer}
      data-label={props.label}
    >
      <div>
        <Label className="label" style={positions} ref={refLabel}>
          {label}
          {/* {label === "prev" && <i className="icon-chevron-w  font-black"></i>}
          {label === "next" && <i className="icon-chevron-e  font-black"></i>} */}
        </Label>
      </div>
    </Container>
  )
}

export default SliderCursorPrevNextText
