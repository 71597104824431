import { useStaticQuery, graphql } from "gatsby"

export const useSiteMetadata = () => {
  const { site, sanitySettings } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            siteTitle
            siteDescription
            siteUrl
            image
            ogLanguage
            author
            twitter
            facebook
          }
        }
        sanitySettings {
          seo {
            metaTitle
            metaDescription
            metaImage {
              asset {
                url
              }
            }
          }
          message404
          contact
        }
      }
    `
  )
  // console.log(global)
  return {
    buildTime: site.buildTime,
    site: site.siteMetadata,
    settings: sanitySettings,
  }
}
