import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Facebook from "./Facebook"
import Twitter from "./Twitter"
import { useSiteMetadata } from "../../hooks/useSiteMetadata"
// import { _getLocale } from "../../core/utils";
// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/
const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteTitle
        # siteDescription
        siteUrl
        image
        ogLanguage
        author
        twitter
        facebook
      }
    }
  }
`

const Seo = ({
  metaTitle,
  metaDescription,
  metaImage,
  pathname,
  page,
  template,
}) => {
  console.log("template", template)
  const { site } = useStaticQuery(query)
  const {
    buildTime,
    siteMetadata: {
      // siteTitle,
      // siteDescription,
      siteUrl,
      image,
      author,
      twitter,
      facebook,
    },
  } = site

  const { settings } = useSiteMetadata()
  // console.log("settings", settings)
  const homeURL = `${siteUrl}`

  const seo = {
    title: page
      ? metaTitle + " - " + settings.seo.metaTitle
      : metaTitle || settings.seo.metaTitle,
    description: metaDescription || "missing desc",
    image: metaImage || settings.seo.metaImage.asset.url,
    url: `${siteUrl}${pathname || ""}`,
  }
  // console.log(seo)

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: homeURL,
    //headline,
    //inLanguage: siteLanguage,
    mainEntityOfPage: homeURL,
    description: seo.description,
    name: seo.title,
    author: {
      "@type": "Person",
      name: author,
    },
    copyrightHolder: {
      "@type": "Person",
      name: author,
    },
    copyrightYear: "2019",
    creator: {
      "@type": "Person",
      name: author,
    },
    publisher: {
      "@type": "Person",
      name: author,
    },
    //datePublished: '2019-01-18T10:30:00+01:00',
    dateModified: buildTime,
    image: {
      "@type": "ImageObject",
      url: `${siteUrl}${image}`,
    },
  }

  let schemaArticle = null

  if (page) {
    schemaArticle = {
      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Person",
        name: author,
      },
      copyrightHolder: {
        "@type": "Person",
        name: author,
      },
      copyrightYear: "2019",
      creator: {
        "@type": "Person",
        name: author,
      },
      publisher: {
        "@type": "Organization",
        name: author,
        image: {
          "@type": "ImageObject",
          url: `${siteUrl}${image}`,
        },
      },
      //datePublished: node.first_publication_date,
      //dateModified: node.last_publication_date,
      description: seo.description,
      headline: seo.title,
      //inLanguage: 'en',
      url: seo.url,
      name: seo.title,
      image: {
        "@type": "ImageObject",
        url: seo.image,
      },
      mainEntityOfPage: seo.url,
    }
  }
  console.log(template)
  return (
    <>
      <Helmet title={seo.title}>
        <html lang={"en-us"} className={`tpl-${template}`} />
        {/* <meta
          name="google-site-verification"
          content="3NHqfd3NH9E-EHrm-yOEvIwaNuFnSus0NT2eNGrV2J8"
        /> */}
        {/* <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          rel="preconnect"
          href="https://fonts.googleapis.com"
          crossorigin
        /> */}
        {/* <link rel="preconnect" href="https://maps.googleapis.com" crossorigin /> */}
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {/* <meta name="gatsby-starter" content="Gatsby Starter Prismic i18n" /> */}
        {!page && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        )}
        {page && (
          <script type="application/ld+json">
            {JSON.stringify(schemaArticle)}
          </script>
        )}

        {/* <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script> */}

        <body className={template} />
      </Helmet>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={page ? "article" : "website"}
        url={seo.url}
        //locale={ogLanguage}
        name={facebook}
      />
      <Twitter
        title={seo.title}
        image={seo.image}
        desc={seo.description}
        username={twitter}
      />
    </>
  )
}

export default Seo

Seo.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  page: PropTypes.bool,
  //node: PropTypes.object,
  locale: PropTypes.string,
}

Seo.defaultProps = {
  title: null,
  desc: null,
  image: null,
  pathname: null,
  page: false,
  //node: null,
  locale: "fr-fr",
}
