import React from "react"
import clsx from "clsx"
import styled from "styled-components"
import SanityTexte from "../SanityTexte"
import SanityImage from "../SanityImage"
import AddToCart from "./AddToCart"
import SlickSlider from "../ui/slick-slider"
import website from "../../../config/website"

const Article = styled.article`
  h2 {
    margin-top: 167px;
    margin-bottom: 129px;
    margin: calc(var(--header-height) * 3) 0;
    @media all and (max-width: 1080px) {
      margin: 10px 0 1em;
    }
  }
  /* .button-o {
    @media all and (max-width: 768px) {
      &::before {
      }
    }
  } */
  @media screen and (min-width: 768px) {
    .slick-slide .gatsby-image-wrapper-constrained {
      height: 100%;
    }
  }
`

const Product = ({ i, input }) => {
  // console.log(input)
  const { title, texte, infos, price, images, priceID, productExternalLink } =
    input
  return (
    <Article className="product min-h-screen">
      <div
        className={clsx(
          "flex flex-col-reverse",
          i % 2 ? "md:flex-row-reverse" : "md:flex-row"
        )}
      >
        <div className="md:w-1/2">
          <div className="h-full ">
            <div className="summary p-md- sticky- top-0-">
              <h2 className="text-_xl md:text-xl mb-1e text-center md:px-md">
                {title}
              </h2>
              <div className="row no-gutter">
                <div className="col-xs-12 col-md-4">
                  <div className="text-_sm md:text-sm px-sm">
                    <SanityTexte input={infos} />
                  </div>
                </div>
                <div className="col-xs-12 col-md-8">
                  <div className="px-sm md:pl-0 md:pr-md mb-md">
                    <SanityTexte input={texte} />
                  </div>
                  {/* <AddToCart
                    // id={slug.current}
                    // slug={slug.current}
                    link={"link"}
                    priceID={priceID}
                    price={price}
                    shopUrl={`${website.url}/shop`}
                    description={infos[0].children[0].text}
                    image={images[0].asset.url}
                    title={title}
                  /> */}
                  {productExternalLink && (
                    <div className="form px-sm mb-lg md:mb-md">
                      <div className="flex justify-end md:justify-start ">
                        <div className="w-1/4  pr-sm">
                          <div className="bt bb ">
                            <div className="price">{price} €</div>
                          </div>
                        </div>
                        <div className="w-2/4  pr-sm">
                          <a
                            className="button button-o w-full hover:bg-accent block text-center"
                            href={productExternalLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            BUY NOW
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-1/2">
          <div className={clsx("")}>
            <SlickSlider>
              {images.map((image, j) => (
                <div className=" md:h-screen" key={j}>
                  <SanityImage
                    image={image}
                    // caption={input.caption}
                    showPlaceholder={false}
                    objectFit="cover"
                  />
                </div>
              ))}
            </SlickSlider>
          </div>
        </div>
      </div>
    </Article>
  )
}

export default Product
